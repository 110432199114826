<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<!-- <bread-crumbs :crumbs="crumbs" @selected="selected"></bread-crumbs> -->
		<service-content-vaccine></service-content-vaccine>
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';
import ServiceContentVaccine from '../components/ServiceContentVaccine.vue';
// import BreadCrumbs from '@/components/common/BreadCrumbs.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
export default {
	components: {
		MainHeader,
		Footer,
		ServiceContentVaccine,
		// BreadCrumbs,
		MenuIntro,
	},
	data() {
		return {
			crumbs: [
				{ name: 'Home', link: '/' },
				{ name: '서비스', link: '/service' },
				{ name: '백신 FC-Anti Virus', link: '/servicevaccine' },
			],
			menuKr: '백신 FC-Anti Virus 서비스',
			menuEn: 'FC-Anti Virus Service',
		};
	},
	methods: {
		selected(crumb) {
			this.$emit('selected', crumb);
			this.$router.push(crumb.link);
		},
	},
};
</script>

<style></style>
