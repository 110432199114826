<template>
	<!--서비스 상세 페이지 - 백신-->
	<div class="Cycontainer">
		<!--content1-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<h2 v-scrollanimation class="header_h2">Malware로 부터 안전하게</h2>
					<h1 v-scrollanimation>FC-Anti Virus</h1>
					<p>
						자사의 차별화된 백신 FC-Anti Virus로<br />개인 업무 환경에 맞추어
						분석하고<br />가장 적합한 백신 서비스를 추천하고 제공합니다.<br />
						다양한 유형의 Malware를 미리 분석하고 치료하며<br />
						자사의 독자적 기술로 신종 · 변종 Malware를 탐지합니다.<br />기관 및
						기업에서 사용하는 PC를 보호하세요.
					</p>
					<div class="servicecontent__btn">
						<button class="btn--md btn--fill-accent">
							<router-link to="/inquiry">지금 문의하기</router-link>
						</button>
						<router-link to="/solutionvaccine">
							<button class="btn--md btn--fill-gray">솔루션 바로가기</button>
						</router-link>
					</div>
				</div>
				<div class="servicecontent__mainimg">
					<img :src="vaccinemain" />
				</div>
			</div>
		</div>
		<!--content1 end-->
		<!--contnet2-->
		<div id="content2">
			<div class="servicecontent__wrapper--imgbg">
				<img :src="vaccineBg" />
				<div class="servicecontent__textbox">
					<h1 v-scrollanimation>클라이언트의 사용 환경을 고려한</h1>
					<div class="servicecontent_stroke"></div>
					<h2 v-scrollanimation>백신 보안 서비스</h2>
					<p v-scrollanimation>
						Malware 탐지 및 치료<br />
						자사의 독자적 기술로 신종 · 변종 Malware를 탐지합니다.
					</p>
				</div>
			</div>
			<div class="servicecontent2_box">
				<div class="servicecontent2_textbox">
					<h1>
						간단한 API호출로<br />탐지부터 치료까지 해결하는
						<span>Anti-Virus</span>
					</h1>
					<div class="border-graient"></div>
					<p>
						ML 기법 활용과 독자적으로 개발한 유사도 알고리즘을 통해<br />웜(Worm),
						트로이목마(Trojan), 랜섬웨어(Ransomeware) 등<br />기존에 알려지지
						않은 Malware를 탐지 및 치료할 수 있는 <br />백신 Anti-Virus입니다.
					</p>
				</div>
			</div>
		</div>
		<!--content3-->
		<div id="content3">
			<div class="content3-title_bg"></div>
			<div class="content3-title">
				<h1>기업 환경을 더 안전하게</h1>
				<p class="servicevaccine-content3">
					개인 정보가 중요한 의료 및 금융기관의 정보들을<br />
					백신 서비스를 이용해 미리 탐지하여 예방해 보세요!
				</p>
			</div>
			<div class="content3_box">
				<img :src="content3img" />
			</div>
		</div>
		<!--content3 end-->
		<div id="content4">
			<div class="content4-title">
				<p>Vaccine Detail Service</p>
				<h2>백신 상세 서비스</h2>
			</div>

			<div class="content4_box">
				<div class="content_box">
					<div class="img_box1"><img :src="pe" /></div>
					<div class="text_box">
						<h2>PE</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							Windows 운영체제에서 사용되는 실행 파일 형식인 PE ( portable
							exevutable )<br />File Format의 Header영역의 data에서 뽑아낸
							특징을 바탕으로<br />
							Malware의 유무를 판단 및 탐지하는 서비스입니다.
						</p>
					</div>
				</div>

				<div class="content_box content_box2">
					<div class="text_box-right">
						<h2>N-gram</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							파일 데이터 영역 안에서 프로그램의 실제 코드가 위치한 부분을
							찾아<br />
							코드 영역에 해당하는 data를 N-gram 분석 기법을 이용해 특징을
							추출하고,<br />
							이를 바탕으로 Malware의 유무를 판단 및 탐지하는 서비스입니다.
						</p>
					</div>
					<div class="img_box2"><img :src="ngram" /></div>
				</div>

				<div class="content_box">
					<div class="img_box1"><img :src="image" /></div>

					<div class="text_box">
						<h2>Image</h2>
						<h3>Malware Detecting Model</h3>
						<div class="border"></div>
						<p>
							프로그램 파일에 대한 바이너리(binary)를 이미지로 변환하고,<br />변환된
							이미지 data에서 뽑아낸 특징을 바탕으로 여러 가지 File에 대한<br />
							악성코드 유무를 판단 및 탐지하는 서비스입니다.
						</p>
					</div>
				</div>
				<div class="content_box content_box2">
					<div class="text_box-right">
						<h2>FC-Anti Virus</h2>
						<h3>Vaccine</h3>
						<div class="border"></div>
						<p>
							PE Malware Detecting Model, N-gram Malware Detecting Model
							<br />
							Image Malware Detecting Model을 적용하여<br />
							신종 또는 변종 악성코드를 탐지하고, 이를 치료하는 백신
							서비스입니다
						</p>
					</div>
					<div class="img_box2"><img :src="ngram" /></div>
				</div>
			</div>
		</div>

		<!--content4 end-->

		<!--content5-->
		<div id="content5Cy">
			<!--content5 title-->
			<div class="content5cy-title">
				<p>Utilization by industry</p>
				<h2>산업별 활용 사례</h2>
			</div>
			<!--content5_box-->
			<div class="content5_box">
				<!--box1-->
				<div class="box1">
					<div class="box_title">
						<h1>01</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>의료기관</h2>
						<p>Malware 탐지를 통한<br />의료 데이터 유출 방지</p>
					</div>
				</div>
				<!--box1 end-->
				<!--box2-->
				<div class="box2">
					<div class="box_title">
						<h1>02</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>분석기관</h2>
						<p>Malware 탐지를 통한<br />기업 PC 보호</p>
					</div>
				</div>
				<!--box2 end-->
				<!--box3-->
				<div class="box3">
					<div class="box_title">
						<h1>03</h1>
						<div class="circle"></div>
					</div>
					<div class="box_subtitle">
						<h2>금융기관</h2>
						<p>Malware 탐지를 통한<br />고객 정보 보호</p>
					</div>
				</div>
			</div>
			<!--box3 end-->
			<!--content5_box end-->
		</div>
		<!--content5 end-->
		<!--content6-->
		<div id="content6">
			<div class="blueBorder servicecontent__wrapper">
				<div class="blueBorder">
					<div class="servicecontent__textbox">
						<h1>요금이 궁금하신가요?</h1>
						<h3>내 비지니스 유형에 적합한 플랜을 찾아보세요!</h3>
					</div>
					<div class="card__wrapper--row">
						<div class="TabletOfCard">
							<div class="card">
								<div class="card_title">
									<h2>모듈러스</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">데이터 분석 프레임워크</div>
									<div class="Card_Text">
										사용자를 생각한 데이터 분석 프레임워크!
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/pricingsecurity"
											>요금확인 바로가기</router-link
										>
									</button>
								</div>
							</div>
							<div class="card">
								<div class="card_title">
									<h2>BI-Mining</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">데이터 시각화 툴</div>
									<div class="Card_Text">
										업무 전반에 걸쳐 필요한<br />
										업무 보조 툴을 한 곳에서!
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/pricingbimining"
											>요금확인 바로가기</router-link
										>
									</button>
								</div>
							</div>
						</div>
						<div class="TabletOfCard">
							<div class="card">
								<div class="card_title">
									<h2>암호스키마</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">데이터를 안전하게 보호</div>
									<div class="Card_Text">
										데이터를 안전하게 보호하는<br />
										솔루션이 필요하다면?
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/solutioncrypto">
											솔루션 바로가기
										</router-link>
									</button>
								</div>
							</div>
							<div class="card">
								<div class="card_title">
									<h2>FC-Anti Virus</h2>
								</div>
								<div class="card__text">
									<div class="card_Head">알고리즘 전용 백신</div>
									<div class="Card_Text">
										머신러닝을 활용하여<br />
										바이러스를 더욱 잘 잡아내는 백신!
									</div>
								</div>
								<div class="btn__transparent">
									<button>
										<router-link to="/solutionvaccine">
											솔루션 바로가기
										</router-link>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--content6 end-->
		<!--content7-->
		<!-- <div class="servicecontent__wrapper center bg contents7">
			<h1>업무 개발 환경에 맞춰 추천 하는 서비스!</h1>
			<p>
				필요한 제품을 확인하고 개발 환경에 맞추어 추천 하는 서비스 입니다!<br />
				지금 바로 진단페이지를 통해 제품을 받아보세요!
			</p>
			<div>
				<button class="btn--md btn--fill-accent">
					<router-link to="/warning">진단 페이지</router-link></button
				><button class="btn--md btn--fill-gray">
					<router-link to="/inquiry">문의 하기</router-link>
				</button>
			</div>
		</div> -->

		<div class="mb__Cont4">
			<div class="TopText_mb">요금이 궁금하신가요?</div>
			<div class="BtmText_mb">내 비지니스 유형에 적합한 플랜을 찾아보세요!</div>
			<router-link to="/pricingsecurity">
				<button class="price_mb">모듈러스 요금 확인</button>
			</router-link>
			<router-link to="/pricingbimining">
				<button class="price_mb">BI Mining 요금 확인</button>
			</router-link>
			<router-link to="/solutioncrypto">
				<button class="price_mb">암호스키마 솔루션 바로가기</button>
			</router-link>
			<router-link to="/solutionvaccine">
				<button class="price_mb">FC-Anti Virus 솔루션 바로가기</button>
			</router-link>
		</div>
	</div>
</template>

<script>
import fcanti from '../assets/images/service/fcanti.svg';
import vaccineBg from '../assets/images/service/vaccinebg.jpg';
import speciality4 from '../assets/images/service/speciality4.svg';
import speciality5 from '../assets/images/service/speciality5.svg';
import speciality6 from '../assets/images/service/speciality6.svg';
import vaccinemain from '../assets/images/service/vaccinemain.svg';
import content3img from '../assets/images/service/servicevaccine-content3.svg';
import pe from '../assets/images/service/vaccine-pe.svg';
import ngram from '../assets/images/service/vaccine-n-gram.svg';
import image from '../assets/images/service/vaccine-image.svg';
import anti from '../assets/images/service/vaccine-fc-antivirus.svg';

export default {
	data() {
		return {
			service: 1,
			fcanti,
			vaccineBg,
			speciality4,
			speciality5,
			speciality6,
			vaccinemain,
			content3img,
			pe,
			ngram,
			image,
			anti,
		};
	},
	methods: {
		showService1() {
			this.service = 1;
		},
		showService2() {
			this.service = 2;
		},
		showService3() {
			this.service = 3;
		},
		showService4() {
			this.service = 4;
		},
		showService5() {
			this.service = 5;
		},
	},
};
</script>

<style></style>
